import About from "../../Components/About";
import Interests from "../../Components/Interests";
import { ImagePageBanner } from "../../Components/PageBanner";
import Pricing from "../../Components/Pricing";
import ResumeSection from "../../Components/ResumeSection";
import Service from "../../Components/Service";
import {
  CodingSkills,
  KnowledgeSkills,
  LanguagesSkills,
} from "../../Components/Skills";
import Layout from "../../Layouts/Index";
const Resume = () => {
  const typingAnimation = [
    `<span class="typed-bread"> <a href="#">Home</a> / Resume </span>`,
  ];
  return (
    <Layout title={"Resume"}>
      {/* Section Started */}
      <ImagePageBanner pageName={"Resume"} typingData={typingAnimation} bannerImg="https://images.unsplash.com/photo-1517336714731-489689fd1ca8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2526&q=80" />
      {/* Section About */}
      <About />
      {/* Section Service */}
      <Service />
      {/* Section Pricing */}
      <Pricing />
      {/* Section Resume */}
      <ResumeSection />
      {/* Section Design Skills */}
      {/* <DesignSkills /> */}
      {/* Section Languages Skills */}
      <LanguagesSkills />
      {/* Section Coding Skills */}
      <CodingSkills />
      {/* Section Knowledge Skills */}
      <KnowledgeSkills />
      {/* Section Interests */}
      <Interests />
      {/* Section Team */}
      {/* <Team /> */}
      {/* Section Testimonials */}
      {/* <Testimonials /> */}
      {/* Section Clients */}
      {/* <Clients /> */}
      {/* Section Custom Text */}
      {/* <CustomText /> */}
    </Layout>
  );
};
export default Resume;
