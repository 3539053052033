import React, { Fragment, useEffect } from 'react'
import Footer from './Footer/Index'
import Head from './Head/Index'
import Header from './Header/Index'
import { cursor, stickyNav } from "../utils";
import VideoPopup from '../Components/popup/VideoPopup'
import ImageView from '../Components/popup/ImageView'
import ImageGallery from '../Components/popup/ImageGallery'
import ContentModal from '../Components/popup/ContentModal';
import Preloader from './Preloader/Index';
import Title from '../Components/Title';

const Layout = ({ children, noHeader , title}) => {
    useEffect(() => {
      cursor();
    }, []);
    useEffect(() => {
      window.addEventListener("scroll", stickyNav);
    }, []);
  return (
    <Fragment>
        <Head/>
        <Title title={title}/>
        <VideoPopup/>
        <ImageView/>
        <ImageGallery/>
        <ContentModal/>
        <Preloader/>
        <div className="container">
        {/* Cursor */}
        <div className="cursor-follower" />
        {/* Header */}
        {!noHeader && <Header />}

        {/* Wrapper */}
        <div className="wrapper">
          {/* Section Started */}
          {children}
        </div>
        {/* Footer */}
        <Footer />
        {/* Lines */}
        <div className="lines">
          <div className="content">
            <div className="line-col" />
            <div className="line-col" />
            <div className="line-col" />
            <div className="line-col" />
            <div className="line-col" />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Layout