const Pricing = () => {
  return (
    <div className="section pricing" id="section-pricing">
      <div className="content">
        {/* title */}
        <div className="title">
          <div className="title_inner">Pricing Table</div>
        </div>
        {/* pricing items */}
        <div className="pricing-items">
          <div className="pricing-col">
            <div className="pricing-item content-box">
              <div className="icon">
                <span className="fas fa-star" />
              </div>
              <div className="name">Basic</div>
              <div className="amount">
                <span className="number">
                  <span className="dollar">Rp.</span>
                  <span>40.000</span>
                  <span className="period">hour</span>
                </span>
              </div>
              <div className="feature-list">
                <ul>
                  <li>Web Development</li>
                  <li>Song Writing</li>
                  <li className="disable">Video Editting</li>
                  <li className="disable">Mobile Application <strong>new</strong> </li>
                </ul>
              </div>
              {/* <div className="bts">
                <a href="#" className="btn hover-animated">
                  <span className="circle" />
                  <span className="lnk">Buy Now</span>
                </a>
              </div> */}
            </div>
          </div>
          <div className="pricing-col">
            <div className="pricing-item content-box">
              <div className="icon">
                <span className="fas fa-rocket" />
              </div>
              <div className="name">Premium</div>
              <div className="amount">
                <span className="number">
                  <span className="dollar">Rp</span>
                  <span>60.000</span>
                  <span className="period">hour</span>
                </span>
              </div>
              <div className="feature-list">
                <ul>
                  <li>Web Development</li>
                  <li>Song Writing</li>
                  <li>Video Editting</li>
                  <li>Mobile Application <strong>new</strong></li>
                </ul>
              </div>
              {/* <div className="bts">
                <a href="#" className="btn hover-animated">
                  <span className="circle" />
                  <span className="lnk">Buy Now</span>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
