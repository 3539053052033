import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

const Menu = () => (
  <Fragment>
    <div className="logo hover-masks-logo">
      <Link to="cv.pdf" target="_blank">
        <span className="mask-lnk">
          M. Bayu <strong>Devara</strong>
        </span>
        <span className="mask-lnk mask-lnk-hover">
          Download <strong>CV</strong>
        </span>
      </Link>
    </div>
    {/* top menu */}
    <div className="top-menu hover-masks">
      <div className="top-menu-nav">
        <div className="menu-topmenu-container">
          <ul className="menu">
            <li className="menu-item">
              <Link>
                <Link to="/">
                  <span className="mask-lnk">Welcome</span>
                  <span className="mask-lnk mask-lnk-hover">To My Home</span>
                </Link>
              </Link>
            </li>
            <li className="menu-item">
              <Link>
                <Link to="/resume">
                  <span className="mask-lnk">My Resume</span>
                  <span className="mask-lnk mask-lnk-hover">Resume</span>
                </Link>
              </Link>
            </li>
            <li className="menu-item">
              <Link>
                <Link to="/work">
                  <span className="mask-lnk">My Work</span>
                  <span className="mask-lnk mask-lnk-hover">Work</span>
                </Link>
              </Link>
            </li>
            <li className="menu-item">
              <Link>
                <Link to="https://arenvy.com" target="_blank">
                  <span className="mask-lnk">My Team</span>
                  <span className="mask-lnk mask-lnk-hover">Arenvy</span>
                </Link>
              </Link>
            </li>
            {/* <li className="menu-item">
              <Link>
                <Link to="/works">
                  <span className="mask-lnk">Works</span>
                  <span className="mask-lnk mask-lnk-hover">Works</span>
                </Link>
              </Link>
            </li>
            <li className="menu-item">
              <Link>
                <Link to="/contact">
                  <span className="mask-lnk">Contact</span>
                  <span className="mask-lnk mask-lnk-hover">Contact</span>
                </Link>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  </Fragment>
);

const Header = () => {
  return (
    <Fragment>
      <MobileHeader />
      <DeskTopHeader />
    </Fragment>
  );
};
export default Header;

const DeskTopHeader = () => (
  <header className={`header desktopHeader`}>
    <div className="head-top">
      <Menu />
    </div>
  </header>
);

const MobileHeader = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <header className={`header mobileHeader ${toggle ? "active" : ""}`}>
      <div className="head-top">
        {/* menu button */}
        <Link to="#" className="menu-btn" onClick={() => setToggle(!toggle)}>
          <span />
        </Link>
        {/* logo */}
        <Menu />
      </div>
    </header>
  );
};
