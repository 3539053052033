import { ImagePageBanner } from "../../Components/PageBanner";
import Layout from "../../Layouts/Index";
import ItemIsotope from "../../Components/ItemIsotope"

const Work = () => {
    const typingAnimation = [
        `<span class="typed-bread"> <a href="#">Home</a> / Works </span>`,
    ];
    return (
        <Layout title={"Works"}>
            <ImagePageBanner pageName={"Works"} typingData={typingAnimation} bannerImg="https://images.unsplash.com/photo-1484807352052-23338990c6c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"/>
            <div className="section works" id="next_section">
                <div className="content">
                    <ItemIsotope />
                    <div className="clear" />
                </div>
            </div>
        </Layout>
    );
};
export default Work;
