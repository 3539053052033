import TypingAnimation from "../Components/TypingAnimations";
import Layout from "../Layouts/Index";
const Index = () => {
  return (
    <Layout title={process.env.REACT_APP_NAME}>
      <div className="section started personal" id="section-started">
        {/* background */}
        <div className="video-bg" style={{ backgroundImage: "url(https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80)" }}>
          <div className="video-bg-mask" />
          <div className="video-bg-texture" id="grained_container" />
        </div>
        {/* started content */}
        <div className="centrize full-width">
          <div className="vertical-center">
            <div className="started-content">
              <div
                className="logo"
                style={{ backgroundImage: "url(gambar/bayu.png)" }}
              />
              <h1 className="h-title">
                DEVV Hello, I’m <strong>Muhammad Bayu Devara</strong>, Backend Developer and{" "}
                <br />
                Video Editor Based in Jakarta, Indonesia.
              </h1>
              <TypingAnimation extraClassName={"typed-subtitle"} />
              <span className="typed-subtitle" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Index;
