import { Route, Routes } from "react-router-dom";
import Index from "./Pages/Index";
import Resume from "./Pages/Resume/Index";
import Work from "./Pages/Work";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Index/>} />
      <Route exact path="/resume" element={<Resume/>} />
      <Route exact path="/work" element={<Work/>} />
      <Route exact path="/contact" element={<Index/>} />
    </Routes>
  );
}

export default App;
