import { useEffect, useRef } from "react";
import Typed from "typed.js";
const TypingAnimation = ({ typingData, extraClassName }) => {
  // Create Ref element.
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: typingData
        ? typingData
        : [
            "I code cool <strong>websites</strong>",
            "I develop <strong>mobile apps</strong>",
            "I develop <strong>cms</strong>",
            "I edit cool <strong>videos</strong>",
            "I write cool <strong>stories</strong>",
          ], // Strings to display
      typeSpeed: 50,
      backSpeed: 30,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <span
      className={`${extraClassName} typing-subtitle`}
      id="subtitle"
      ref={el}
    ></span>
  );
};
export default TypingAnimation;
