import { Link } from "react-router-dom";
import moment from 'moment'
const About = () => {
  return (
    <div className="section about" id="next_section">
      <div className="content content-box">
        {/* image */}
        <div className="image">
          <img src="gambar/resume_profile.png" alt="" />
        </div>
        {/* desc */}
        <div className="desc">
          <p>
            Hello! I’m Bayu Devara. Backend Developer from Indonesia, Jakarta. I have rich experience in backend and CMS applications developing,
            I also have other skills such as writing stories, editting videos. Need some app to build? Let's talk.
          </p>
          <div className="info-list">
            <ul>
              <li>
                <strong>Age:</strong> { moment().diff("1997-05-09","years") }
              </li>
              <li>
                <strong>Residence:</strong> Jakarta, Indonesia
              </li>
              <li>
                <strong>Freelance:</strong> Available
              </li>
              <li>
                <strong>Address:</strong> East Jakarta
              </li>
              <li>
                <strong>Phone:</strong> +62 878 8595 8453
              </li>
              <li>
                <strong>E-mail:</strong> bayudevara@gmail.com
              </li>
            </ul>
          </div>
          <div className="bts">
            <Link to="/cv.pdf" target="_blank" className="btn hover-animated">
              <span className="circle" />
              <span className="lnk">Download CV</span>
            </Link>
          </div>
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};
export default About;
